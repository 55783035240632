
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px 97px 0 66px;
    .make-content-header{
      width: 100%;
      background: #FFFDF0;
      border: 1px solid #FFC8A0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 8px 46px;
      span{
        font-size: 14px;
        color: #333;
        font-weight: 400;
        line-height: 30px;
        strong{
          font-weight: 400;
          color: #2461EF;
        }
      }
    }
    .make-content-body{
      display: flex;
      flex-direction: column;
      .content-body-title{
        font-size: 16px;
        line-height: 1;
        margin: 38px 0 0 0;
      }
      .content-body-title2{
        font-size: 16px;
        line-height: 1;
        padding: 30px 0 8px;
      }
      .content-body-form{
        margin: 29px 0 0 15px;
        ::v-deep .el-form{
          .el-form-item{
            .el-form-item__label{
              padding: 0;
            }
          }
          .form-item-message{
            line-height: 1;
            font-size: 12px;
            color: #999999;
            padding: 10px 0 4px;
          }
        }
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
.tax-administration-src{
  display: flex;
  flex-direction: column;
  span{
    //line-height: 1;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    &:nth-child(2){
      line-height: 1;
    }
    &:last-of-type{
      padding: 10px 0 4px;
      color: #999999;
      font-size: 12px;
      line-height: 1;
    }
    strong{
      font-weight: 400;
      color: #2461EF;
    }
  }
  .upload-img-box{
    margin-top: 10px;
    display: flex;
    .upload-img, .upload-box{
      width: 120px;
      height: 120px;
      &.upload-img{
        margin-right: 40px;
        position: relative;
        i{
          line-height: 1;
          font-size: 12px;
          color: #F44336;
          position: absolute;
          right: -20px;
          top: 0;
          cursor: pointer;
        }
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      &.upload-box{
        background: #F7F7F7;
        text-align: center;
        line-height: 120px;
        cursor: pointer;
        i{
          font-size: 24px;
          color: #BFBFBF;
        }
      }
    }
  }
}
.cut-off-line{
  height: 1px;
  border-bottom: 1px dashed #D7DDE4;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
::v-deep .tax-e-src-class{
  position: relative;
  margin-bottom: 40px;
  &::after{
    content: '';
    height: 1px;
    width: calc(100% + 177px);
    position: absolute;
    left: -81px;
    bottom: -40px;
    border-bottom: 1px dashed #D7DDE4;
  }
}
::v-deep .select-address .el-form-item__content {
  display: flex;
  flex-direction: column;
  .el-col{
    display: flex;
  }
}
