
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px 97px 0 66px;
    .make-content-header{
      width: 100%;
      background: #FFFDF0;
      border: 1px solid #FFC8A0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 8px 46px;
      span{
        font-size: 14px;
        color: #333;
        font-weight: 400;
        line-height: 30px;
        strong{
          font-weight: 400;
          color: #2461EF;
        }
      }
    }
    .make-content-body{
      display: flex;
      flex-direction: column;
      .content-body-title{
        font-size: 16px;
        line-height: 1;
        margin: 30px 0 0 0;
        span{
          font-size: 12px;
          color: #666666;
          margin-left: 15px;
          a{
            color: #2461EF;
            text-decoration: none;
          }
        }
      }
      .content-body-form{
        margin: 6px 0 0 0px;
        ::v-deep .el-form{
          .el-form-item{
            .el-form-item__label{
              padding: 0;
            }
          }
          .form-item-message{
            line-height: 1;
            font-size: 12px;
            color: #999999;
            padding: 10px 0 4px;
          }
          .el-cascader{
            .is-disabled{
              .el-input__inner{
                padding-right: 0;
              }
              .el-input__suffix{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
.green-dialog{
  position: relative;
  .close-icon{
    position: absolute;
    right: 26px;
    top: 18px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
}
::v-deep .el-cascader-panel{
  height: 287px;
  .el-cascader-menu__wrap {
    height: 287px;
    .in-active-path{
      background: #E7F6EF;
    }
  }
}
