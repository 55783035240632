
.make-box{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .make-scrollbar{
    flex: 1;
    height: 1%;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
    }
  }
  .make-scrollbar-box{
    display: flex;
    flex-direction: column;
    .make-scrollbar-header{
      padding: 0 20px;
      .logo-box{
        width: 227px;
        height: 40px;
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .make-scrollbar-main{
      display: flex;
      justify-content: center;
      .scrollbar-main-flow{
        border: 1px solid #E5E5E5;
        margin: 20px 0 20px 20px;
        display: flex;
        flex-direction: column;
        .main-flow-header{
          background: #F9F9F9;
          display: flex;
          align-items: center;
          height: 88px;
          padding: 0 74px 16px;
          box-sizing: border-box;
          .step-circle{
            width: 20px;
            height: 20px;
            background: #D5D5D5;
            border-radius: 50%;
            text-align: center;
            position: relative;
            .step-num{
              font-size: 16px;
              color: #fff;
            }
            &:first-of-type,&:last-of-type{
              width: 32px;
              height: 32px;
              .step-num{
                line-height: 32px;
              }
              .step-text{
                bottom: -22px;
                left: -12px;
              }
            }
            .step-text{
              display: inline-block;
              font-size: 14px;
              color: #999999;
              line-height: 1;
              position: absolute;
              bottom: -28px;
              left: -18px;
              width: 56px;
            }
            &.current{
              background: #2DC079;
              .step-text{
                color: #2DC079;
              }
            }
          }
          .step-line{
            width: 254px;
            background: #D5D5D5;
            height: 3px;
            &.current{
              background: #2DC079;
            }
          }
        }
        .main-flow-settled{
          display: flex;
        }
        //.main-flow-footer{
        //  padding: 10px 72px 10px 0;
        //  //box-sizing: border-box;
        //  text-align: right;
        //  border-top: solid #DCDCDC 1px;
        //}
      }
    }
  }
  .make-store-set{
    display: flex;
  }
}
