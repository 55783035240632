
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px 97px 0 66px;
    .make-content-header{
      width: 100%;
      background: #FFFDF0;
      border: 1px solid #FFC8A0;
      box-sizing: border-box;
      display: flex;
      padding: 24px 36px;
      .header-left{
        display: flex;
        align-items: center;
        i{
          font-size: 36px;
          color: #FF9450;
        }
      }
      .header-right{
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        span{
          font-size: 14px;
          color: #FF9450;
          font-weight: 400;
          line-height: 30px;
          strong{
            font-weight: 400;
            color: #2461EF;
          }
        }
      }
    }
    .make-content-body{
      display: flex;
      flex-direction: column;
      .body-contact-information{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .contact-information-title{
          font-size: 16px;
          line-height: 1;
        }
        .contact-information-message{
          margin-top: 18px;
          border: 1px solid #E5E5E5;
          background: #F5F7F6;
          display: flex;
          flex-direction: column;
          padding: 10px 38px;
          span{
            line-height: 24px;
            color: #333333;
            font-size: 14px;
          }
        }
      }
      .body-enter-state{
        display: flex;
        flex-direction: column;
        margin-top: 35px;
        .enter-state-title{
          font-size: 16px;
          line-height: 1;
        }
        .state-title-message{
          margin-top: 18px;
          display: flex;
          //justify-content: space-between;
          border: 1px solid #2DC079;
          background: #E7F6EF;
          padding: 16px 76px 16px 36px;
          .title-message-left{
            display: flex;
            align-items: center;
            i{
              font-size: 30px;
              line-height: 1;
              color: #2DC079;
            }
            span{
              font-size: 18px;
              margin-left: 14px;
            }
          }
          .title-message-middle{
            display: flex;
            flex-direction: column;
            margin-left: 38px;
            span{
              font-size: 14px;
              line-height: 24px;
            }
          }
          .title-message-right{
            flex: 1;
            width: 1%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            .el-button{
              padding: 12px;
              width: 88px;
            }
          }
        }
      }
      .body-audit-journal{
        display: flex;
        flex-direction: column;
        margin-top: 54px;
        .audit-journal-title{
          font-size: 16px;
          line-height: 1;
        }
        .audit-journal-message{
          margin: 30px 0;
          ::v-deep .el-table{
            .el-table__header-wrapper .has-gutter tr {
              .is-leaf{
                &:first-of-type{
                  border-right: 1px solid #fff;
                }
                &:nth-child(2){
                  border-right: 2px solid #fff;
                }
              }
            }
            .el-table__body-wrapper .el-table__body .el-table__row .is-center {
              &:first-of-type{
                border-right: 1px solid #fff;
              }
              &:nth-child(2){
                border-right: 2px solid #fff;
              }
            }
          }
        }
      }
    }
  }
}
