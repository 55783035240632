
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    padding: 22px 0 10px 74px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .make-box-header{
    line-height: 1;
    color: #666666;
    font-size: 12px;
  }
  .make-settled-one{
    display: flex;
    flex-direction: column;
    margin-top: 34px;
    .make-settled-title{
      font-size: 16px;
      line-height: 1;
    }
    .make-settled-table{
      display: flex;
      margin: 29px 0 0 53px;
      .settled-table-body{
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #E5E5E5;
        border-right: none;
        &:nth-child(odd){
          background: #F8F8F8;
        }
        &:last-of-type{
          border-right: 1px solid #E5E5E5;
        }
        .table-body-item{
          width: 200px;
          text-align: center;
          line-height: 1;
          margin: 25px 0;
          font-size: 14px;
          .warning{
            font-size: 14px;
            color: #FFAA00;
            margin-left: 6px;
          }
          .arrows{
            font-size: 40px;
            color: #E1E1E1;
            position: absolute;
            right: -26px;
            top: 5px;
          }
          &:first-of-type{
            padding: 17px 0;
            border-bottom: 1px solid #E5E5E5;
            margin: 0;
            font-size: 16px;
            position: relative;
          }
        }
      }
    }
  }
  .make-settled-two{
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    .make-settled-title{
      font-size: 16px;
      line-height: 1;
    }
    .make-settled-qualification{
      display: flex;
      margin: 26px 0 0 53px;
      .settled-qualification-item{
        display: flex;
        flex-direction: column;
        width: 150px;
        margin-left: 75px;
        &:first-of-type{
          margin-left: 0;
        }
        .qualification-item-top{
          display: flex;
          flex-direction: column;
          background: #2DC079;
          line-height: 1;
          align-items: center;
          padding: 30px 0 22px;
          i{
            color: #FFFFFF;
            font-size: 50px;
          }
          span{
            margin-top: 10px;
            color: #FFFFFF;
            font-size: 20px;
          }
        }
        .qualification-item-bottom{
          text-align: center;
          font-size: 12px;
          color: #666666;
          line-height: 1;
          margin-top: 10px;
        }
      }
    }
  }
  .make-settled-three{
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    .make-settled-title{
      font-size: 16px;
      line-height: 1;
    }
    .settled-three-multiple{
      display: flex;
      flex-direction: column;
      ::v-deep .el-checkbox-group{
        display: flex;
        flex-direction: column;
        margin: 14px 0 0 45px;
         .el-checkbox__label{
           font-size: 12px;
           color: #666666;
           line-height: 36px;
        }
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
