
.store-set-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .store-set-title{
    font-size: 16px;
    color: #333;
    line-height: 1;
  }
  .store-set-body{
    display: flex;
    flex-direction: column;
    .store-set-form{
      margin: 22px 0 0 66px;
    }
  }
}
.store-logo-src{
  display: flex;
  flex-direction: column;
  .logo-msg{
    color: #333333;
    font-size: 14px;
  }
  .upload-img-box{
    margin-top: 9px;
    display: flex;
    .upload-img, .upload-box{
      &.upload-img{
        width: 180px;
        height: 60px;
        margin-right: 40px;
        position: relative;
        margin-left: 10px;
        i{
          line-height: 1;
          font-size: 12px;
          color: #F44336;
          position: absolute;
          right: -20px;
          top: 0;
          cursor: pointer;
        }
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      &.upload-box{
        width: 60px;
        height: 60px;
        border: 1px solid #DCDCDC;
        background: #F7F7F7;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1;
        justify-content: center;
        span{
          font-size: 12px;
          color: #666666;
          margin-top: 8px;
        }
        i{
          font-size: 16px;
          color: #BFBFBF;
        }
      }
    }
  }
}
::v-deep .el-form{
  .el-form-item{
    .el-form-item__label{
      line-height: 1;
    }
    .el-form-item__content{
      line-height: 1;
    }
  }
}
::v-deep .tox-tinymce {
  width: 80%;
}
