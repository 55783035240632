
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 68px 0 0 104px;
    .make-content-header{
      font-size: 16px;
      line-height: 1;
    }
    .make-content-form{
      margin:30px 0 0px 32px;
      ::v-deep .el-form{
        .el-form-item{
          .el-form-item__label{
            padding: 0;
          }
        }
      }
      .form-item-message{
        line-height: 1;
        font-size: 12px;
        color: #999999;
        padding: 8px 0 4px;
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
